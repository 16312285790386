<template>
  <div>

    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>计量单位</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-col :xs="{span:24,offset:0}" :sm="{span:16,offset:0}" :md="{span:12,offset:0}" :lg="{span:6,offset:0}" :xl="{span:4,offset:0}">
          <el-input placeholder="请输入内容" v-model="querInfo.gu_name" clearable @clear="inputchange">
            <el-button slot="append" icon="el-icon-search" @click="inputchange"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="addgoodsunitDialog" icon="el-icon-document-add" v-if="is_add">添加</el-button>
        </el-col>
      </el-row>
      <el-table :data="goodsunitlist" border stripe v-loading="loading">
        <el-table-column type="index"></el-table-column>
        <el-table-column label="单位名称" prop="gu_name"></el-table-column>
        <el-table-column label="创建时间" prop="add_date"></el-table-column>
        <el-table-column label="添加人" prop="add_uid"></el-table-column>
        <el-table-column label="操作" width="180px">
          <template slot-scope="scope">
            <!-- 编辑 -->
            <el-button type="primary" size="mini"
                       @click="showEditDialog(scope.row.gu_id)" v-if="is_edit" icon="el-icon-edit"></el-button>
            <!--删除-->
            <el-button type="danger" size="mini"
                       @click="removeWareById(scope.row.gu_id)" v-if="is_del" icon="el-icon-delete-solid"></el-button>

          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </el-card>
    <!-- 添加对话框-->
    <el-dialog
        title="添加计量单位" :visible.sync="addgoodsunitDialogVisible" width="50%" @close="addgoodsunitDialogClose">
      <!--    添加的表单    -->
      <el-form :model="addgoodsunitForm" :rules="addgoodsunitFormRules" ref="addgoodsunitFormRef" label-width="150px">
        <el-form-item label="计量单位名称：" prop="gu_name">
          <el-input v-model="addgoodsunitForm.gu_name"></el-input>
        </el-form-item>



      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addgoodsunitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addgoodsunit" :loading="addloading">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑对话框-->
    <el-dialog
        title="编辑计量单位" :visible.sync="editgoodsunitDialogVisible" width="50%" @close="addgoodsunitDialogClose">
      <!--    添加分类的表单    -->
      <el-form :model="editgoodsunitForm" :rules="addgoodsunitFormRules" ref="addgoodsunitFormRef" label-width="150px">
        <el-form-item label="计量单位名称：" prop="gu_name">
          <el-input v-model="editgoodsunitForm.gu_name"></el-input>
        </el-form-item>



      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editgoodsunitDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editgoodsunit" :loading="editloading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return{
      addloading:false,
      editloading:false,
      //计量单位的添加
      goodsunitlist:[],
      //分页
      querInfo: {
        page: 1,
        limit: 10,
        gu_name:''
      },
      total:0,
      //页面权限
      goodsunitJurisdiction:[],
      //添加按钮的展示与隐藏
      is_add:false,
      is_edit:false,
      is_del:false,
      //表格加载
      loading:true,
      //添加计量单位
      addgoodsunitForm:{
        gu_name:''
      },
      //编辑计量单位
      editgoodsunitForm:{},
      //控制添加对话框的显示和隐藏
      addgoodsunitDialogVisible:false,
      //控制编辑对话框的显示和隐藏
      editgoodsunitDialogVisible:false,
      //添加分类表单的验证规则对象
      addgoodsunitFormRules: {
        gu_name: [
          {required: true, message: '请输入计量单位的名称', trigger: 'blur'},
        ]
      },
    }
  },
  created() {
    this.getgoodsunitList()
    this.getgoodsunitJurisdiction()
  },
  methods: {
    //获取权限接口
    async getgoodsunitJurisdiction(){
      this.loading=true
      let str = window.sessionStorage.getItem('activePath');
      let activePath = str.split("/").join("");
      const { data : res } = await this.$http.get('menu/getMenuChildrenList', {params:{m_path : activePath}})
      if (res.code!=200)return  this.$message.error(res.msg)
      this.goodsunitJurisdiction=res.data
      this.goodsunitJurisdiction.forEach((item)=>{
        if (item.m_path=='add'){
          this.is_add=true
        }else if (item.m_path=='edit'){
          this.is_edit=true
        }else if(item.m_path=='del'){
          this.is_del=true
        }
      })
    },
    async getgoodsunitList() {
      this.loading=true
      const {data: res} = await this.$http.get('goodsunit/getList',
          {params: this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值给
      this.goodsunitlist = res.data.data
      //把数据赋值给total
      this.total = res.data.total
      this.loading=false
    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getgoodsunitList()
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getgoodsunitList()
    },
    inputchange(){
      this.querInfo.page=1
      this.getgoodsunitList()
    },
    // 添加计量单位
    addgoodsunitDialog(){
      this.addgoodsunitDialogVisible=true
    },
    //监听添加对话框的关闭事件
    addgoodsunitDialogClose(){
      this.addgoodsunitForm.gu_name=''
      this.addloading=false
      this.$refs.addgoodsunitFormRef.resetFields()
    },
    //提交
    async addgoodsunit(){
      this.addloading=true
      const {data:res} = await this.$http.post('goodsunit/add',this.addgoodsunitForm)
      this.addloading=false
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.addgoodsunitDialogVisible=false
      this.getgoodsunitList()
    },
    //编辑  查找当前id
    async showEditDialog(id){
      this.editgoodsunitDialogVisible=true
      const {data:res} = await this.$http.get(`goodsunit/find/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.editgoodsunitForm=res.data
    },
    //提交编辑 goodsunit/edit
    async editgoodsunit(){
      this.editloading=true
      const {data:res} = await this.$http.post('goodsunit/edit',this.editgoodsunitForm)
      this.editloading=false
      if (res.code!=200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.editgoodsunitDialogVisible=false
      this.getgoodsunitList()
    },
    //删除 goodsunit/del/12
    async removeWareById(id){
      const confirmResult = await this.$confirm(
          '此操作将永久删除该计量单位, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).catch(err => err)
      if (confirmResult !== 'confirm') {
        return this.$message.info('已经取消删除')
      }
      const {data:res} = await this.$http.post(`goodsunit/del/${id}`)
      if (res.code!=200)return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.getgoodsunitList()

    }

  }
}
</script>
